// Pricing Form Fields Storage Variable
const activeClass = 'step--active';
let pricingFormFields = {};

$('.step .step__content form').submit(function(e) {
	e.preventDefault();
});

// Add forms validation
$('.js-form-validation').submit(function(e) {
	let $form = $(this);

	let isFormValid = validateFieldsFromForm($form);

	if (!isFormValid) {
		e.stopPropagation();
		e.stopImmediatePropagation();
	}
});


// Step one functionality
$('.step--one form').submit(function(e) {
	let $this = $(this);

	saveDataFromTheForm($this.serializeArray());

	nextStep($this);

	fillValuesInAside();
});


// Step two functionality
$('.step--two form').submit(function(e) {
	let $this = $(this);

	saveDataFromTheForm($this.serializeArray());

	nextStep($this);

	fillValuesInAside();

	// Add dynamically html in the next steps based on the options selected in this step
	dynamicallyCreateStepThreeHTML();
	dynamicallyCreateStepFourHTML();
});


// Step three functionality
$('.step--three form').submit(function(e) {
	let $this = $(this);

	saveDataFromTheForm($this.serializeArray());

	nextStep($this);

	dynamicallyAddUnitsAsideToStep($('.step--four'));

	fillValuesInAside();
});

// Step four functionality
$('.step--four form').submit(function(e) {
	let $this = $(this);

	saveDataFromTheForm($this.serializeArray());

	dynamicallyCreateStepFiveHTML();

	nextStep($this);

	dynamicallyAddUnitsAsideToStep($('.step--five'));

	fillValuesInAside();
});

// Step five functionality
$('.step--five form').submit(function(e) {

	let $this = $(this);

	saveDataFromTheForm($this.serializeArray());

	dynamicallyCreateStepSixHTML();

	nextStep($this);

	dynamicallyAddUnitsAsideToStep($('.step--six'));

	fillValuesInAside();
});

// Step six functionality
$('.step--six form').submit(function(e) {
	let $this = $(this);

	saveDataFromTheForm($this.serializeArray());

	dynamicallyCreateStepSeven();

	nextStep($this);

	dynamicallyAddUnitsAsideToStep($('.step--seven'));

	fillValuesInAside();
});

// Step seven functionality
$('.step--seven .step__content form').submit(function(e) {
	let $this = $(this);

	dynamicallyCreateStepEight();

	nextStep($this);

	dynamicallyAddUnitsAsideToStep($('.step--eight'));

	fillValuesInAside();
});

// Step eight functionality
$('.step--eight .step__content form').submit(function(e) {
	let $this = $(this);

	saveDataFromTheForm($this.serializeArray());

	dynamicallyCreateStepNine();

	nextStep($this);

	dynamicallyAddUnitsAsideToStep($('.step--nine'));

	fillValuesInAside();
});


$('.step--eight .step__products-wrapper').on('click', '.js-select-product', function(e) {
	e.preventDefault();

	let $this = $(this);

	if ( $this.hasClass( 'btn--selected' ) ) {
		return;
	}

	let $stepProducts = $this.closest('.step__products');
	let $inputProduct = $stepProducts.children('input:first-child');
	let $inputSelection = $stepProducts.children('input:nth-child(2)');
	
	let $selectedProductBtn = $stepProducts.find('.product__action .btn--selected');

	if ( $selectedProductBtn.length ) {
		$selectedProductBtn.removeClass('btn--selected');
	}

	$this.addClass('btn--selected');
	$inputProduct.val($this.attr('href'));
	$inputSelection.val($this.data('selection'));
});

$(document).on('gform_confirmation_loaded', function(event, formId){
    let $currentStep = $('.step--active');
	let $thankYouStep = $('.step--ten');

	if (!$thankYouStep.length) {
		return;
	}
	
	$currentStep.removeClass(activeClass);
	$thankYouStep.addClass(activeClass);
	
	$('html, body').animate({
		scrollTop: $thankYouStep.offset().top - 200
	}, 400);

	dynamicallyAddUnitsAsideToStep($('.step--ten'));
	fillValuesInAside();
});

function nextStep($currentForm) {
	let $currentStep = $currentForm.closest('.step');
	let $nextStep = $currentStep.next();

	if (!$nextStep.length) {
		return;
	}

	$currentStep.removeClass(activeClass);
	$nextStep.addClass(activeClass);
	
	$('html, body').animate({
		scrollTop: $nextStep.offset().top - 200
	}, 400);
}

// Handle back buttons in the form
$('.step .btn--blue').on('click', function(e) {
	e.preventDefault();

	let $currentStep = $(this).closest('.step');
	let $prevStep = $currentStep.prev();

	if (!$prevStep.length) {
		return;
	}

	$currentStep.removeClass(activeClass);
	$prevStep.addClass(activeClass);

	$('html, body').animate({
		scrollTop: $prevStep.offset().top - 200
	}, 400)
});

function fillValuesInAside() {
	let $details = $('.detail[data-detail-key]');

	$details.each(function() {
		let $this = $(this);
		let key = $this.data('detail-key');

		if (!(key in pricingFormFields)) {
			return;
		}

		let $paragraph = $(this).find('.detail__content p');

		if ( !$paragraph.length ) {
			return;
		}

		$paragraph.text(pricingFormFields[key]);
	});

}
function saveDataFromTheForm(fields) {
	// Convert the array of objects to key value array.
	for ( let key in fields ) {
		let name = fields[key]['name'];
		
		if (name.endsWith('-size')) {
			pricingFormFields[name + '-id'] = $('[name="' + name + '"] option:selected').data('id');
		}

		pricingFormFields[fields[key]['name']] = fields[key]['value'];
	}
}

function validateFieldsFromForm($form) {
	// Validate fields
	let $fields = $form.find('input, select');
	let allFieldsValid = true;
	let isFormValid = true;

	$fields.each(function() {
		let fieldValid = validateField($(this));

		if (!fieldValid) {
			isFormValid = false
		}
	});

	return isFormValid;
}

function validateField($field, showErrors = true) {
	let fieldType = $field.prop('type');
	let fieldValue = $field.val();

	if (fieldType === 'radio') {
		let $fieldContainer = $field.closest('.js-radios-container');
		let isValid = validateValueByRule($field.prop('name'), 'radio');

		if (isValid !== true)  {
			if (showErrors) {
				let $isAlreadyAnError = $fieldContainer.find('.error-message');

				if ( !$isAlreadyAnError.length ) {
					addFieldContainerError($fieldContainer, isValid);
				}
			}

			return false;
		}

		removeFieldContainerError($fieldContainer);
		return true;
	}

	let $fieldContainer = $field.parent();
	let validateRules = $field.data('validate-rules');

	if (!validateRules) {
		return true;
	}

	let rules = validateRules.split('|');

	for (let i = 0; i < rules.length; i++) {
		let isValid = validateValueByRule(fieldValue, rules[i]);

		if (isValid !== true)  {
			if (showErrors) {
				addFieldContainerError($fieldContainer, isValid);
			}

			return false;
		}
	}

	removeFieldContainerError($fieldContainer);
	return true;
}

function addFieldContainerError($fieldContainer, error) {
	$fieldContainer.addClass('field-not-valid');

	let $errorMessage = $fieldContainer.find('.error-message');
	
	if ($errorMessage.length) {
		$errorMessage.remove();
	}

	$fieldContainer.append('<div class="error-message"><p>' + error + '</p></div>');
}

function removeFieldContainerError($fieldContainer) {
	$fieldContainer.removeClass('field-not-valid');

	let $errorMessage = $fieldContainer.find('.error-message');

	if ($errorMessage.length) {
		$errorMessage.remove();
	}
}

function dynamicallyCreateStepThreeHTML() {
	let html =
	`<div class="units__item">
		<div class="units__title">
			<h3>UNIT #{{UNIT_NUMBER}}</h3>
		</div><!-- /.units__title -->
		
		<div class="radios-units js-radios-container">
			<ul>
				<li>
					<div class="radio-unit">
						<input type="radio" class="radiobtn" name="unit-{{UNIT_NUMBER}}-system" id="unit-{{UNIT_NUMBER}}-system-1" value="Split">
						
						<label for="unit-{{UNIT_NUMBER}}-system-1">
							<span>SPLIT SYSTEM</span>

							<span class="radio__image">
								<img src="{{SPLIT_SYSTEM_IMAGE}}">
							</span>
						</label>
					</div><!-- /.radio -->
				</li>
				
				<li>
					<div class="radio-unit">
						<input type="radio" class="radiobtn" name="unit-{{UNIT_NUMBER}}-system" id="unit-{{UNIT_NUMBER}}-system-2" value="Packaged">
						
						<label for="unit-{{UNIT_NUMBER}}-system-2">
							<span>PACKAGED SYSTEM</span>

							<span class="radio__image">
								<img src="{{PACKAGED_SYSTEM_IMAGE}}">
							</span>
						</label>
					</div><!-- /.radio -->
				</li>
			</ul>
		</div><!-- /.radios -->
	</div><!-- /.units__item -->`;

	let unitsCount = pricingFormFields['units'];
	let unitItems = $('.step--three .units .units__items');
	let splitSystemImage = unitItems.data('split-system-image');
	let packagedSystemImage = unitItems.data('packaged-system-image');

	unitItems.empty();

	for(let i = 1; i <= unitsCount; i++) {
		let unitItem = html;
		unitItem = unitItem.split('{{UNIT_NUMBER}}').join('' + i);
		unitItem = unitItem.replace('{{SPLIT_SYSTEM_IMAGE}}', splitSystemImage);
		unitItem = unitItem.replace('{{PACKAGED_SYSTEM_IMAGE}}', packagedSystemImage);

		unitItems.append($(unitItem));
	}
}

function dynamicallyAddUnitsAsideToStep($step) {
	let html =
	`<li>
		<div class="details">
			<div class="details__items">
				<div class="details__item">
					<div class="detail">
						<div class="detail__aside">
							<p>UNIT #{{UNIT_NUMBER}}</p>
						</div><!-- /.detail__aside -->
					</div><!-- /.detail -->	
				</div>

				<div class="details__item">
					<div class="detail" data-detail-key="unit-{{UNIT_NUMBER}}-system">
						<div class="detail__aside">
							<p>SYSTEM</p>
						</div><!-- /.detail__aside -->

						<div class="detail__content">
							<p></p>
						</div><!-- /.detail__content -->
					</div><!-- /.detail -->	
				</div>

				<div class="details__item">
					<div class="detail" data-detail-key="unit-{{UNIT_NUMBER}}-source">
						<div class="detail__aside">
							<p>SOURCE</p>
						</div><!-- /.detail__aside -->

						<div class="detail__content">
							<p></p>
						</div><!-- /.detail__content -->
					</div><!-- /.detail -->	
				</div>

				<div class="details__item">
					<div class="detail" data-detail-key="unit-{{UNIT_NUMBER}}-location">
						<div class="detail__aside">
							<p>LOCATION</p>
						</div><!-- /.detail__aside -->

						<div class="detail__content">
							<p></p>
						</div><!-- /.detail__content -->
					</div><!-- /.detail -->	
				</div>

				<div class="details__item">
					<div class="detail" data-detail-key="unit-{{UNIT_NUMBER}}-size">
						<div class="detail__aside">
							<p>SIZE</p>
						</div><!-- /.detail__aside -->

						<div class="detail__content">
							<p></p>
						</div><!-- /.detail__content -->
					</div><!-- /.detail -->	
				</div>

				<div class="details__item">
					<div class="detail" data-detail-key="unit-{{UNIT_NUMBER}}-selection">
						<div class="detail__aside">
							<p>SELECTION</p>
						</div><!-- /.detail__aside -->

						<div class="detail__content">
							<p></p>
						</div><!-- /.detail__content -->
					</div><!-- /.detail -->	
				</div>
			</div><!-- /.details__items -->
		</div><!-- /.details -->						
	</li>`;

	let steps = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];
	let currentStep = false;
	// Remove details__item that are not for the current step.
	for ( let key in steps ) {
		if ( $step.hasClass( 'step--' + steps[key] ) ) {
			currentStep = parseInt(key) + 1;
		}
	}

	if ( !currentStep || currentStep < 4 ) {
		return;
	}

	if ( currentStep >= 8 ) {
		currentStep--;
	}

	let detailsOnStepCount = currentStep - 2;

	// Remove the details after the details on the step count.
	let $html = $(html);
	$html.find('.details__items .details__item:nth-child('+ detailsOnStepCount + ')').nextAll().remove();
	html = $html.prop('outerHTML');

	let unitsCount = pricingFormFields['units'];
	let unitsAside = $step.find('.step__details');

	unitsAside.find('li:not(:first-child)').remove();

	for(let i = 1; i <= unitsCount; i++) {
		let unitItem = html;
		unitItem = unitItem.split('{{UNIT_NUMBER}}').join('' + i);
		unitsAside.append($(unitItem));
	}
}

function dynamicallyCreateStepFourHTML() {
	let html =
	`<div class="units__item">
		<div class="units__title">
			<h3>UNIT #{{UNIT_NUMBER}}</h3>
		</div><!-- /.units__title -->
		
		<div class="radios-units js-radios-container">
			<ul>
				<li>
					<div class="radio-unit radio-unit--secondary">
						<input type="radio" class="radiobtn" name="unit-{{UNIT_NUMBER}}-source" id="unit-{{UNIT_NUMBER}}-source-1" value="Electric">
						
						<label for="unit-{{UNIT_NUMBER}}-source-1">
							<span>ELECTRIC</span>

							<span class="radio__image">
								<img src="{{ELECTRIC_HEATING_IMAGE}}">
							</span>
						</label>
					</div><!-- /.radio -->
				</li>
				
				<li>
					<div class="radio-unit radio-unit--secondary">
						<input type="radio" class="radiobtn" name="unit-{{UNIT_NUMBER}}-source" id="unit-{{UNIT_NUMBER}}-source-2" value="Gas">
						
						<label for="unit-{{UNIT_NUMBER}}-source-2">
							<span>GAS</span>

							<span class="radio__image">
								<img src="{{GAS_HEATING_IMAGE}}">
							</span>
						</label>
					</div><!-- /.radio -->
				</li>
			</ul>
		</div><!-- /.radios -->
	</div><!-- /.units__item -->`;

	let unitsCount = pricingFormFields['units'];
	let unitItems = $('.step--four .units .units__items');
	let electricHeatingImage = unitItems.data('electric-heating-image');
	let gasHeatingImage = unitItems.data('gas-heating-image');

	unitItems.empty();

	for(let i = 1; i <= unitsCount; i++) {
		let unitItem = html;
		unitItem = unitItem.split('{{UNIT_NUMBER}}').join('' + i);
		unitItem = unitItem.replace('{{ELECTRIC_HEATING_IMAGE}}', electricHeatingImage);
		unitItem = unitItem.replace('{{GAS_HEATING_IMAGE}}', gasHeatingImage);

		unitItems.append($(unitItem));
	}
}

function dynamicallyCreateStepFiveHTML() {
	let splitLocationOptionsHTML = 
	`
	<div class="units__item">
		<div class="units__title">
			<h3>UNIT #{{UNIT_NUMBER}}</h3>
		</div><!-- /.units__title -->
		
		<div class="radios-units radios-units--secondary js-radios-container">
			<ul>
				<li>
					<div class="radio-unit">
						<input type="radio" class="radiobtn" name="unit-{{UNIT_NUMBER}}-location" id="unit-{{UNIT_NUMBER}}-location-1" value="Garage">
						
						<label for="unit-{{UNIT_NUMBER}}-location-1">
							<span>GARAGE</span>

							<span class="radio__image radio__image--secondary image-fit js-image-fit">
								<img src="{{GARAGE_IMAGE}}">
							</span>
						</label>
					</div><!-- /.radio -->
				</li>
				
				<li>
					<div class="radio-unit">
						<input type="radio" class="radiobtn" name="unit-{{UNIT_NUMBER}}-location" id="unit-{{UNIT_NUMBER}}-location-2" value="Closet">
						
						<label for="unit-{{UNIT_NUMBER}}-location-2">
							<span>CLOSET</span>

							<span class="radio__image radio__image--secondary image-fit js-image-fit">
								<img src="{{CLOSET_IMAGE}}">
							</span>
						</label>
					</div><!-- /.radio -->
				</li>

				<li>
					<div class="radio-unit">
						<input type="radio" class="radiobtn" name="unit-{{UNIT_NUMBER}}-location" id="unit-{{UNIT_NUMBER}}-location-3" value="Attic">
						
						<label for="unit-{{UNIT_NUMBER}}-location-3">
							<span>ATTIC</span>

							<span class="radio__image radio__image--secondary image-fit js-image-fit">
								<img src="{{ATTIC_IMAGE}}">
							</span>
						</label>
					</div><!-- /.radio -->
				</li>
			</ul>
		</div><!-- /.radios -->
	</div><!-- /.units__item -->`;

	let packagedLocationOptionsHTML =
	`<div class="units__item">
		<div class="units__title">
			<h3>UNIT #{{UNIT_NUMBER}}</h3>
		</div><!-- /.units__title -->
		
		<div class="radios-units radios-units--secondary js-radios-container">
			<ul>
				<li>
					<div class="radio-unit">
						<input type="radio" class="radiobtn" name="unit-{{UNIT_NUMBER}}-location" id="unit-{{UNIT_NUMBER}}-location-4" value="Roof">
						
						<label for="unit-{{UNIT_NUMBER}}-location-4">
							<span>ROOF</span>

							<span class="radio__image radio__image--secondary image-fit js-image-fit">
								<img src="{{ROOF_IMAGE}}">
							</span>
						</label>
					</div><!-- /.radio -->
				</li>
				
				<li>
					<div class="radio-unit">
						<input type="radio" class="radiobtn" name="unit-{{UNIT_NUMBER}}-location" id="unit-{{UNIT_NUMBER}}-location-5" value="Next To House">
						
						<label for="unit-{{UNIT_NUMBER}}-location-5">
							<span>NEXT TO HOUSE</span>

							<span class="radio__image radio__image--secondary image-fit js-image-fit">
								<img src="{{NEXT_TO_HOUSE_IMAGE}}">
							</span>
						</label>
					</div><!-- /.radio -->
				</li>
			</ul>
		</div><!-- /.radios -->
	</div><!-- /.units__item -->`;

	let unitsCount = pricingFormFields['units'];
	let unitItems = $('.step--five .units .units__items');
	
	let garageImage = unitItems.data('garage-image');
	let closetImage = unitItems.data('closet-image');
	let atticImage = unitItems.data('attic-image');
	let roofImage = unitItems.data('roof-image');
	let nextToHouseImage = unitItems.data('next-to-house-image');

	unitItems.empty();

	for(let i = 1; i <= unitsCount; i++) {
		let unitItem = splitLocationOptionsHTML;

		let unitSystem = $('input[name="unit-' + i + '-system"]:checked').val();
		if (unitSystem === 'Packaged') {
			unitItem = packagedLocationOptionsHTML;
		}

		unitItem = unitItem.split('{{UNIT_NUMBER}}').join('' + i);
		unitItem = unitItem.split('{{GARAGE_IMAGE}}').join(garageImage);
		unitItem = unitItem.split('{{CLOSET_IMAGE}}').join(closetImage);
		unitItem = unitItem.split('{{ATTIC_IMAGE}}').join(atticImage);
		unitItem = unitItem.split('{{ROOF_IMAGE}}').join(roofImage);
		unitItem = unitItem.split('{{NEXT_TO_HOUSE_IMAGE}}').join(nextToHouseImage);

		unitItems.append($(unitItem));
	}
}

function dynamicallyCreateStepSixHTML() {
	let squareFootageTonnageOptions = $('#square-footage-tonnage');
	
	if ( !squareFootageTonnageOptions.length ) {
		return;
	}

	let html =
	`<div class="units__item">
		<div class="units__title">
			<h3>UNIT #{{UNIT_NUMBER}}</h3>
		</div><!-- /.units__title -->
		
		<div class="select">
			<select name="unit-{{UNIT_NUMBER}}-size" id="unit-{{UNIT_NUMBER}}-size" data-validate-rules="required">` +
				squareFootageTonnageOptions.html();
			`</select>
		</div><!-- /.select -->
	</div><!-- /.units__item -->`;

	let unitsCount = pricingFormFields['units'];
	let unitItems = $('.step--six .units .units__items');

	unitItems.empty();

	for(let i = 1; i <= unitsCount; i++) {
		let unitItem = html;
		unitItem = unitItem.split('{{UNIT_NUMBER}}').join('' + i);
		unitItems.append($(unitItem));
	}
}

function dynamicallyCreateStepSeven () {
	let unitsCount = pricingFormFields['units'];
	let unitTypes = [];
	let unitType = '';
	for(let i = 1; i <= unitsCount; i++) {
		if ( ! unitTypes.includes( pricingFormFields['unit-' + i + '-system' ] ) ) {
			unitTypes.push( pricingFormFields['unit-' + i + '-system' ] );
		}
	}

	if (unitTypes.length > 1) {
		unitType = 'Multiple';
	} else {
		unitType = unitTypes[0];
	}

	let $steps = $('.step--seven .step__type');

	$steps.hide();
	$steps.each( function( index ) {
		if ( $(this).data('type') === unitType ) {
			$(this).show();
		}
	} );
}

function dynamicallyCreateStepEight() {
	let $stepEight = $('.step--eight');
	let $stepContent = $stepEight.find('.step__content');
	let $stepProducts = $stepEight.find('.step__products-wrapper');

	$stepContent.addClass('step__content--loading');
	$stepProducts.empty();

	$.get({
		url: crbVar.admin_ajax,
		data: {
			'action': 'crb_get_products_by_selected_options',
			'data': pricingFormFields
		},
		success: function(response) {
			$stepContent.removeClass('step__content--loading');

			if (!response['success']) {
				console.log(response);

				return;
			}

			$stepProducts.append(response['data']);
		},
		error: function(error) {
			$stepContent.removeClass('step__content--loading');

			console.log(error);
		}
	});

	$.post({
		url: crbVar.admin_ajax,
		data: {
			'action': 'crb_handle_custom_form_content',
			'data': pricingFormFields
		},
		success: function(response) {
			console.log('success');
			
		},
		error: function(error) {
			$stepContent.removeClass('step__content--loading');

			console.log(error);
		}
	});
}

function dynamicallyCreateStepNine() {
	let $stepNine = $('.step--nine');
	let $stepContent = $stepNine.find('.step__content');
	let $unitItems = $stepNine.find('.units .units__items');
	
	$stepContent.addClass('step__content--loading');

	$unitItems.empty();
	$stepContent.addClass('step__content--loading');

	$.post({
		url: crbVar.admin_ajax,
		data: {
			'action': 'crb_send_mails_to_client_and_admin',
			'data': pricingFormFields
		},
		success: function(response) {
			$stepContent.removeClass('step__content--loading');

			if (!response['success']) {
				console.log( response );
				$unitItems.append('<h2>' + response['data'] + '<h2>');
				
				return;
			}

			let unitsCount = pricingFormFields['units'];
			for(let i = 1; i <= unitsCount; i++) {
				let htmlString = '<h2>Not selected option for unit</h2>';
				let productBody = $('.step--eight .step__products:nth-child(' + i + ') .btn--selected');

				if ( productBody.length ) {
					let $htmlFormated = productBody.closest('.product__body').clone();

					let $selectedBtns = $htmlFormated.find('.btn--selected');
					if ( $selectedBtns.length ) {
						let financingBtnHref = $unitItems.data('btn-link');

						$selectedBtns.removeClass('btn--selected');
						$selectedBtns.text('FINANCING');
						$selectedBtns.prop('href', financingBtnHref);
					}

					htmlString = $htmlFormated.html();
				}

				let unitItem = 
				`<div class="units__item">
					<div class="units__title">
						<h3>UNIT #{{UNIT_NUMBER}}</h3>
					</div><!-- /.units__title -->
					
					<div class="units__product">
						<div class="product">
							<div class="product__body">
								` + htmlString + `
							</div><!-- /.product__body -->
						</div><!-- /.product -->
					</div><!-- /.units__product -->
				</div><!-- /.units__item -->`;
				
				unitItem = unitItem.split('{{UNIT_NUMBER}}').join('' + i);
				$unitItems.append($(unitItem));
			}
		},
		error: function(error) {
			$stepContent.removeClass('step__content--loading');

			console.log(error);
		}
	});

	$.post({
		url: crbVar.admin_ajax,
		data: {
			'action': 'crb_handle_custom_form_content',
			'data': pricingFormFields
		},
		success: function(response) {
			console.log('success');
			
		},
		error: function(error) {
			$stepContent.removeClass('step__content--loading');

			console.log(error);
		}
	});
}

/**
 * Validation Functionality
 */
let ValidationFunctions = {};

ValidationFunctions.validateFormValueRequired = function (value) {
	let errorMsg = 'This field is required';

	if (!value) {
		return errorMsg;
	}

	return true;
}

ValidationFunctions.validateFormValueEmail = function (value) {
	let errorMsg = 'Email is invalid, please fill a correct one';

	var regexEmailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isEmailValid = regexEmailValidation.test(String(value).toLowerCase());

    if (!isEmailValid) {
    	return errorMsg;
    }

	return true;
}

ValidationFunctions.validateFormValueRadio = function (value) {
	let errorMsg = 'This field is required, please check one of the options';

	let isRadioValid = $('input[name="' + value + '"]:checked').length;

    if (!isRadioValid) {
    	return errorMsg;
    }

	return true;
}

function validateValueByRule(value, rule) {
	let validateFunctionName = 'validateFormValue' + rule.charAt(0).toUpperCase() + rule.slice(1);
	
	return ValidationFunctions[validateFunctionName](value);
}
