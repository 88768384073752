
$('.step__products-wrapper').on('click', '.js-accordion .accordion__head', function() {
	$(this)
		.toggleClass('active')
		.siblings()
			.slideToggle()
			.toggleClass('hidden');
});


$('.step__products-wrapper').on('click', '.js-accordion-open .accordion__head', function() {
	$(this)
		.parent()
		.toggleClass('active');
});
