import Swiper from 'swiper/js/swiper';

/**
 *  js-slider-intro
 */
$('.js-slider-reviews .swiper-container').each((i, slider) => {
	console.log(i)
	const $slider = $(slider);
	const paginationEl = $slider.find('.swiper-pagination');

	const swiper = new Swiper(slider, {
		pagination: {
			clickable: true,
	        el: paginationEl,
	     },
	});
});