/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */

import './modules/init-slider-reviews';
import './modules/product-accordion';
import './modules/popups';
import './modules/nav-trigger';
import './modules/pricing-form';
import './modules/tabs';
