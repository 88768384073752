import 'magnific-popup';

$('.popup-youtube').magnificPopup({
	type: 'iframe'
});

$('.js-popup-text-content').magnificPopup({
  type: 'inline',
  midClick: true,
  mainClass: 'popup-text'
});